import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';

import { I18nNs } from '@/util/i18n.ts';
import { Room } from '@/value-object/room/room.vo.ts';

import { useChatStore } from '../../store/chat.store.ts';

export function ChatTabs() {
  const { t } = useTranslation([I18nNs.Enum, I18nNs.Chat]);
  const types = useChatStore((s) => s.types);
  const room = useChatStore((s) => s.room);
  const setRoom = useChatStore((s) => s.setRoom);

  if (types.length <= 1) return null;
  if (!room) return null;

  return (
    <Tabs
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        background: '#ffffff',
        zIndex: 100,
      }}
      variant="fullWidth"
      value={room?.type}
      onChange={(_e, type) => {
        setRoom(Room.create(type, room.docId));
      }}
    >
      {types.map((ty) => (
        <Tab key={ty} label={t(`chatType.${ty}`)} value={ty} />
      ))}
    </Tabs>
  );
}
