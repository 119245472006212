import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { isDate } from 'date-fns/isDate';
import { useCallback, useState } from 'react';

import { chatSocket } from '@/socket/chat/chat.socket.ts';

import { useChatStore } from '../../store/chat.store.ts';

export function InputRemember() {
  const [rememberAt, setRememberAt] = useState<Date | null>(null);
  const room = useChatStore((s) => s.room);

  const sendMessage = useCallback(() => {
    if (!room) return;
    if (!isDate(rememberAt)) return;
    chatSocket.emit('sendMessage', { ...room.toJSON(), rememberAt });
    setRememberAt(null);
  }, [room, rememberAt]);

  return (
    <Stack direction="row" alignItems="stretch">
      <DateTimePicker displayWeekNumber onChange={setRememberAt} value={rememberAt} />
      <Button onClick={sendMessage} disabled={!rememberAt}>
        <SendIcon />
      </Button>
    </Stack>
  );
}
