import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatType } from '@/enum/chat/chat-type.enum.ts';
import { chatSocket } from '@/socket/chat/chat.socket.ts';

import { useChatStore } from '../store/chat.store.ts';

type DataType = {
  docId: string;
  type: ChatType;
  typing: string[];
};

export function useTyping(): string | null {
  const [me, setMe] = useState<string>('');
  const [typing, setTyping] = useState<string[]>([]);
  const { t } = useTranslation('component');

  const room = useChatStore((s) => s.room);

  const onTyping = useCallback(
    (data: DataType) => {
      if (!room) return;
      if (data.docId !== room.docId) return;
      if (data.type !== room.type) return;
      setTyping(data.typing.filter((name) => name !== me));
    },
    [me, room],
  );

  useEffect(() => {
    chatSocket.emit('getMyName', (name) => setMe(name));
    chatSocket.on('onUserTyping', onTyping);

    return () => {
      chatSocket.off('onUserTyping', onTyping);
    };
  }, [onTyping]);

  const response = useMemo(() => {
    if (typing.length === 0) return null;
    if (typing.length === 1) return t('chat.typing.one', { name: typing[0] });
    const and = t('chat.typing.and');
    const responseArray = typing.reduce<string[]>((acc, name, id) => {
      if (typing.length - 1 === id) return [...acc, name];
      return [...acc, name, ','];
    }, []);

    responseArray[responseArray.length - 2] = and;

    return t('chat.typing.many', { name: responseArray.join(' ') });
  }, [typing, t]);

  return response;
}
