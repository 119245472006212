import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { uuidValidator } from '../../common/input/uuid.input.ts';
import { ImpersonateOutput } from './impersonate.output.ts';

const path = '/auth/impersonate/:userId';

export const impersonate = async (userId: string): Promise<string> => {
  try {
    await uuidValidator.validate(userId);
    const url = getUrl(path, { userId });
    return await http.post<ImpersonateOutput>(url).then((res) => res?.xsrf);
  } catch (err) {
    return handleError(path, err, { userId });
  }
};
