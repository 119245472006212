import { ChatLineType } from '@/enum/chat/chat-line-type.enum.ts';
import { ChatOutput } from '@/socket/chat/dto/chat.output.ts';

import { LastView } from './LastView.tsx';
import { Message } from './Message.tsx';

type Message = ChatOutput['lines'][0];

type Props = {
  line: Message;
};

export function Line({ line }: Props) {
  if (line.type === ChatLineType.MESSAGE) {
    return <Message line={line} />;
  }

  return <LastView line={line} />;
}
