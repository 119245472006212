import Box from '@mui/material/Box';
import { ReactNode, Suspense as ReactSuspense, useEffect, useMemo, useState } from 'react';

import { Loader } from '../Loader/Loader.tsx';

type Props = {
  children: ReactNode;
  disableLoader?: boolean;
};

export const Suspense = ({ children, disableLoader = false }: Props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setShowLoader(true), 200);
  }, []);

  const loader = useMemo(() => {
    if (disableLoader) return false;
    return showLoader;
  }, [disableLoader, showLoader]);

  return (
    <ReactSuspense
      fallback={
        loader ? (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Loader />
          </Box>
        ) : null
      }
    >
      {children}
    </ReactSuspense>
  );
};
