import { useMemo, useRef } from 'react';

import { UseListAllOutput } from '@/api/common/output/use-list-all.output.ts';
import { UsePageOutput } from '@/api/common/output/use-page.output.ts';
import { OptionType } from '@/type/option-search.type.ts';

type Props<List> = {
  values: string[];
  useFind: (params?: object | null) => UsePageOutput<List> | UseListAllOutput<List>;
  dbToOptionType: (data: List) => OptionType;
};

export function useValues<List>({
  values: fieldValues,
  useFind,
  dbToOptionType,
}: Props<List>): OptionType[] {
  const values: string[] = useMemo(() => {
    if (!Array.isArray(fieldValues)) return [];
    return fieldValues;
  }, [fieldValues]);

  const findValuesParam = useMemo(() => {
    if (values.length === 0) return null;
    return { ids: [...values].sort() };
  }, [values]);

  const { data: findData } = useFind(findValuesParam);

  // Trasform page or all or null to simple array
  const saveFindData = useRef<List[]>([]);
  const data: List[] = useMemo(() => {
    if (Array.isArray(findData)) return findData;
    if (findData?.docs && Array.isArray(findData?.docs)) return findData.docs;
    return saveFindData.current;
  }, [findData]);

  saveFindData.current = data;

  const oldData = useRef<OptionType[]>([]);
  const res = useMemo(() => {
    const index = new Map<string, OptionType>();

    (Array.isArray(data) ? data : []).forEach((d) => {
      const option = dbToOptionType(d);
      index.set(option.value, option);
    });

    oldData.current = values.reduce<OptionType[]>((acc, value) => {
      const option = index.get(value);
      if (!option) return acc;
      return [...acc, option];
    }, []);

    return oldData.current;
  }, [dbToOptionType, values, data]);

  return res;
}
