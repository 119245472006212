import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http, silenthttp } from '@/util/http/http.ts';

import { RefreshOutput } from './refresh.output.ts';

const path = '/auth/refresh';

export const refresh = async (silent: boolean = true): Promise<string> => {
  try {
    const url = getUrl(path);

    if (silent) {
      return await silenthttp.get<RefreshOutput>(url).then((res) => res.xsrf);
    }

    return await http.get<RefreshOutput>(url).then((res) => res.xsrf);
  } catch (err) {
    if (silent) throw err;

    return handleError(path, err, {});
  }
};
