import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import upperFirst from 'lodash/upperFirst';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageGallery } from '@/component/ImageGallery/ImageGallery.tsx';
import { useConfirm } from '@/context/ConfirmContext/useConfirm.ts';
import { useLang } from '@/hook/useLang.ts';
import { useUserId } from '@/hook/user/useUserId.ts';
import { chatSocket } from '@/socket/chat/chat.socket.ts';
import { ChatOutput } from '@/socket/chat/dto/chat.output.ts';
import { getDateTimeString } from '@/util/date.ts';
import { I18nNs } from '@/util/i18n.ts';

import { useChatStore } from '../../store/chat.store.ts';
import { StyledMessage, StyledMessageBubble } from './Message.style.ts';
import { useMessage } from './useMessage.tsx';

type Line = ChatOutput['lines'][0];

type Props = {
  line: Line;
};

export function Message({ line }: Props) {
  const message = line.message;
  const [beforeDelete, setBeforeDelete] = useState<boolean>(false);
  const msgRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation([I18nNs.Component, I18nNs.Chat]);
  const lang = useLang();
  const confirm = useConfirm();
  const room = useChatStore((s) => s.room);

  const userId = useUserId();
  const isMe: boolean = useMemo(() => userId === message?.user?.id, [message, userId]);

  const onDeleteHandle = useCallback(async () => {
    if (!message?.id) return;
    if (!isMe) return;
    if (!room) return;
    setBeforeDelete(true);
    const del = await confirm(t('chat.message.delete'));
    setBeforeDelete(false);
    if (!del) return;
    chatSocket.emit('deleteMessage', {
      id: message.id,
      ...room.toJSON(),
    });
  }, [message?.id, isMe, confirm, t, room]);

  const userOrBotMessage = useMessage(message?.message || '', message?.user?.isBot || false);

  if (!message) return null;

  return (
    <StyledMessage ref={msgRef}>
      <Stack direction={isMe ? 'row-reverse' : 'row'}>
        <Avatar src={message.user.avatar} alt={message.user.name}>
          {message.user.initials}
        </Avatar>
        <StyledMessageBubble $me={isMe} $beforeDelete={beforeDelete}>
          {isMe && (
            <IconButton color="secondary" size="small" className="delete" onClick={onDeleteHandle}>
              <CloseIcon />
            </IconButton>
          )}
          {!isMe && <div className="user-name">{message.user.name}</div>}

          {userOrBotMessage && <div>{userOrBotMessage}</div>}
          {message.rememberAt && (
            <Typography variant="body2">
              <em>
                {t(`chat.message.remember.${isMe ? 'me' : 'other'}`, {
                  name: message.user.name,
                  date: getDateTimeString(message.rememberAt, lang),
                })}
              </em>
            </Typography>
          )}
          {Array.isArray(message.medias) && message.medias.length > 0 && (
            <ImageGallery medias={message.medias} />
          )}

          <div className="date">{upperFirst(getDateTimeString(message.createdAt, lang))}</div>
        </StyledMessageBubble>
      </Stack>
    </StyledMessage>
  );
}
