import Color from 'color';
import styled from 'styled-components';

import { Line } from '../Line/Line.tsx';

export const StyledChatTyping = styled.div`
  margin: 0 20px 20px;
  .typing-loader {
    width: 30px;
  }

  svg {
    opacity: 0.8;
  }
  & > div,
  & > p {
    display: inline-block;
    color: ${Color('#242424').alpha(0.5).toString()};
  }
`;

export const StyledChatLine = styled(Line)`
  margin: 10px 20px;
`;

export const StyledChatScrollBottomBtn = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  opacity: 0.8;
  z-index: 9000;
  transition-property: bottom, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &:hover {
    opacity: 1;
  }

  &.sticky {
    bottom: -100px;
    opacity: 0;
  }
`;
