import swr, { SWRResponse } from 'swr';

import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { uuidValidator } from '../../common/input/uuid.input.ts';
import { GetPurchaseProductOutput } from './get-purchase-product.output.ts';

const path = '/purchase-product/:purchaseProductId';

export const getPurchaseProduct = async (
  purchaseProductId: string,
): Promise<GetPurchaseProductOutput> => {
  try {
    await uuidValidator.validate(purchaseProductId);
    const url = getUrl(path, { purchaseProductId });
    return await http.get<GetPurchaseProductOutput>(url);
  } catch (err) {
    return handleError(path, err, { purchaseProductId });
  }
};

export const useGetPurchaseProduct = (
  purchaseProductId: string | null,
): SWRResponse<GetPurchaseProductOutput> => {
  const url = purchaseProductId ? getUrl(path, { purchaseProductId }) : null;
  return swr<GetPurchaseProductOutput>(url, () => getPurchaseProduct(purchaseProductId as string));
};
