import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { uuidValidator } from '../../common/input/uuid.input.ts';

const path = '/media/:mediaId';

export const deleteMedia = async (mediaId: string): Promise<void> => {
  try {
    await uuidValidator.validate(mediaId);
    const url = getUrl(path, { mediaId });
    await http.delete(url);
  } catch (err) {
    handleError(path, err, { mediaId });
  }
};
