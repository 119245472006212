import React, { useCallback, useEffect } from 'react';

import { mutateHistory } from '@/api/history/list-histories/list-histories.controller.ts';
import { historySocket } from '@/socket/history/history.socket.ts';

import { HistorySocketContext } from './HistorySocketContext.ts';

type Props = {
  children: React.ReactNode;
};

export function HistorySocketProvider({ children }: Props): React.ReactElement {
  const mutate = useCallback(() => {
    mutateHistory();
  }, []);

  useEffect(() => {
    historySocket.on('mutate', mutate);
    return () => {
      historySocket.off('mutate', mutate);
    };
  }, [mutate]);

  return <HistorySocketContext.Provider value={null}>{children}</HistorySocketContext.Provider>;
}
