import styled from 'styled-components';

export const FieldMediaStyle = styled.li`
  .media-filename {
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Input = styled.input`
  width: auto;
  padding: 4px 10px;
  border: 1px solid rgba(0 0 0 / 12%);
  font-size: 1rem;
  line-height: 50px;
  height: 50px;
`;

export const SquareImage = styled.div<{ $crop: boolean; $img: string }>`
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(0 0 0 / 12%);
  background-image: url('${({ $img }) => $img}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: ${({ $crop }) => ($crop ? 'cover' : 'contain')};
`;
