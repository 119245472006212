import { Manager } from 'socket.io-client';

import { config } from '@/util/config.ts';

// URL de WebSocket
const wsUrl = new URL('/', config.URL_API);
wsUrl.protocol = 'wss';

// Instance unique de Manager
export const socketManager = new Manager(wsUrl.href, {
  autoConnect: true,
  withCredentials: true,
});
