import { ReactElement } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import type { UseOutput } from '@/api/common/output/use.output.ts';
import type { UseListAllOutput } from '@/api/common/output/use-list-all.output.ts';
import type { UsePageOutput } from '@/api/common/output/use-page.output.ts';
import { OptionType } from '@/type/option-search.type.ts';

import { SelectSearchMulti } from './component/SelectSearchMulti/SelectSearchMulti.tsx';
import { SelectSearchSingle } from './component/SelectSearchSingle/SelectSearchSingle.tsx';

interface Props<T extends FieldValues, List, Item> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  useFind: (params?: object | null) => UsePageOutput<List> | UseListAllOutput<List>;
  useFindOne: (id: string | null) => UseOutput<Item>;
  dbToOptionType: (doc: List) => OptionType;
  findParams?: object;
  disabled?: boolean;
  loading?: boolean;
  multi?: boolean;
  size?: 'small' | 'medium';
}

export function SelectSearch<T extends FieldValues, List, Item>({
  multi = false,
  ...props
}: Props<T, List, Item>): ReactElement {
  if (multi) {
    return <SelectSearchMulti<T, List> {...props} />;
  }
  return <SelectSearchSingle<T, List, Item> {...props} />;
}
