import 'react-photoswipe/lib/photoswipe.css';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import prettyBytes from 'pretty-bytes';
import { useCallback, useMemo } from 'react';
import { PhotoSwipeGallery, PhotoSwipeGalleryItem } from 'react-photoswipe';

import { GetMediaOutput } from '@/api/media/get-media/get-media.output.ts';

import { StyledImageGallery } from './ImageGallery.style.ts';

type Props = {
  medias: GetMediaOutput[];
};

export function ImageGallery({ medias }: Props) {
  const mediaItems: PhotoSwipeGalleryItem[] = useMemo(
    () =>
      medias
        .filter((media) => media?.isImage)
        .map((media) => ({
          src: media.url,
          thumbnail: media.urlMin,
          w: media?.imageWidth,
          h: media?.imageHeight,
          title: media.filename,
        })),
    [medias],
  );
  const fileItems = useMemo(() => medias.filter((media) => !media?.imageWidth), [medias]);

  const getThumbnailContent = useCallback(
    (item: any) => <img src={item.thumbnail} alt={item.title} />,
    [],
  );

  // const galleryUID: string = useMemo(() => medias.map((m) => m.id).join('_'), [medias]);

  return (
    <StyledImageGallery>
      {mediaItems.length > 0 && (
        <div className="images">
          <PhotoSwipeGallery
            items={mediaItems}
            thumbnailContent={getThumbnailContent}
            options={{ history: false }}
          />
        </div>
      )}
      {fileItems.length > 0 && (
        <ul className="files">
          {fileItems.map((media) => (
            <li key={media.id}>
              <a href={media.url} target="_blank" rel="noopener noreferrer">
                <AttachFileIcon fontSize="small" />
                {media.filename}
                {media.size && <em>&nbsp; ({prettyBytes(media.size)})</em>}
              </a>
            </li>
          ))}
        </ul>
      )}
    </StyledImageGallery>
  );
}
