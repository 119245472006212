import { useEffect, useRef } from 'react';
import Sortable from 'sortablejs';

import { useMediaField } from './context/useMediaField.ts';
import { FieldMedia } from './FieldMedia.tsx';
import { List } from './FieldMedias.style.ts';

export function FieldMedias() {
  const { medias, onSwap, readOnly } = useMediaField();

  const ref = useRef<HTMLUListElement>(null);
  useEffect(() => {
    const actualRef = ref.current;

    if (!actualRef) return;
    if (readOnly) return;
    if (!ref.current) return;

    const sortable = Sortable.create(ref.current, {
      draggable: '.sortable-draggable',
      handle: '.draggable',
      animation: 250,
      ghostClass: 'sortable-gost',
      onEnd: (e) => {
        if (typeof e.oldIndex !== 'number') {
          return;
        }
        if (typeof e.newIndex !== 'number') {
          return;
        }
        onSwap(e.oldIndex, e.newIndex);
      },
    });

    return () => {
      if (actualRef) {
        sortable.destroy();
      }
    };
  }, [ref, onSwap, readOnly]);

  return (
    <div>
      <List ref={ref}>
        {medias.map((id) => (
          <FieldMedia key={id} mediaId={id} />
        ))}
      </List>
    </div>
  );
}
