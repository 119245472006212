import swr, { SWRResponse } from 'swr';

import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { GetConfigOutput } from './get-config.output.ts';

const path = '/config';

export const getConfig = async (): Promise<GetConfigOutput> => {
  try {
    const url = getUrl(path);
    return await http.get<GetConfigOutput>(url);
  } catch (err) {
    return handleError(path, err, {});
  }
};

export const useGetConfig = (): SWRResponse<GetConfigOutput> => {
  const url = getUrl(path);
  return swr<GetConfigOutput>(url, () => getConfig());
};
