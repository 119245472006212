import swr, { SWRResponse } from 'swr';

import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http, silenthttp } from '@/util/http/http.ts';

import { GetMeOutput } from './get-me.output.ts';

const path = '/auth/me';

export const getMe = async (silent: boolean = true): Promise<GetMeOutput> => {
  try {
    const url = getUrl(path);
    if (silent) {
      return await silenthttp.get<GetMeOutput>(url);
    }

    return await http.get<GetMeOutput>(url);
  } catch (err) {
    return handleError(path, err);
  }
};

export const useGetMe = (): SWRResponse<GetMeOutput> => {
  const url = getUrl(path);
  return swr<GetMeOutput>(url, getMe, {});
};
