import { useCallback } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useListChatUsers } from '@/api/chat/list-chat-users/list-chat-users.controller.ts';
import { ListChatUsersOutput } from '@/api/chat/list-chat-users/list-chat-users.output.ts';
import { useFakeOne } from '@/api/util/use-fake-one/use-fake-one.ts';
import { OptionType } from '@/type/option-search.type.ts';
import { I18nNs } from '@/util/i18n.ts';

import { SelectSearch } from '../SelectSearch/SelectSearch.tsx';

type UserList = ListChatUsersOutput['docs'][0];
type UserItem = UserList;

type Props<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  findParams?: object;
  loading?: boolean;
  multi?: boolean;
};

export function SelectSearchUserForChat<T extends FieldValues>({
  name,
  control,
  label,
  findParams,
  loading,
  multi = false,
}: Props<T>) {
  const { t } = useTranslation(I18nNs.Field);

  const userToOptionType = useCallback(
    (user: UserList): OptionType => ({
      label: user.fullName,
      value: user.id,
    }),
    [],
  );

  return (
    <SelectSearch<T, UserList, UserItem>
      useFind={useListChatUsers}
      useFindOne={useFakeOne<UserList>}
      dbToOptionType={userToOptionType}
      label={label || t('user')}
      name={name}
      control={control}
      findParams={{ ...findParams }}
      loading={loading}
      multi={multi}
    />
  );
}
