import '@/util/i18n';
import 'react-indiana-drag-scroll/dist/style.css';
import 'simplebar-react/dist/simplebar.min.css';

import { ThemeProvider } from '@emotion/react';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enGB as en } from 'date-fns/locale/en-GB';
import { fr } from 'date-fns/locale/fr';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { ErrorFallback } from '@/component/ErrorFallback/ErrorFallback.tsx';
import { BreadcrumbProvider } from '@/context/BreadcrumbContext/BreadcrumbProvider.tsx';
import { ChatProvider } from '@/context/ChatContext/ChatProvider.tsx';
import { ConfirmProvider } from '@/context/ConfirmContext/ConfirmProvider.tsx';
import { HeaderProvider } from '@/context/HeaderContext/HeaderProvider.tsx';
import { HistorySocketProvider } from '@/context/HistorySocketContext/HistorySocketProvider.tsx';
import { RedirectProvider } from '@/context/RedirectContext/RedirectProvider.tsx';
import { SnackProvider } from '@/context/SnackContext/SnackProvider.tsx';
import { TitleProvider } from '@/context/TitleContext/TitleProvider.tsx';
import { theme } from '@/util/theme.ts';

import { AppGlobalStyle } from './App.style.ts';
import { LoadingProvider } from './context/LoadingContext/LoadingProvider.tsx';
import { PopupLoaderProvider } from './context/PopupLoaderContext/PopupLoaderProvider.tsx';
import { useLang } from './hook/useLang.ts';
// test
import { Router } from './router/Router.tsx';

export default function App() {
  const lang = useLang();

  const adapterLocal = useMemo(() => (lang === 'fr' ? fr : en), [lang]);

  const snackbarAction = useCallback(
    (snackbarId: string | number) => (
      <IconButton onClick={() => closeSnackbar(snackbarId)}>
        <Close />
      </IconButton>
    ),
    [],
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <SnackbarProvider
            preventDuplicate
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{ containerAnchorOriginTopRight: 'snackbarPosition' }}
            action={snackbarAction}
          >
            <LoadingProvider>
              <PopupLoaderProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocal}>
                  <SnackProvider>
                    <BrowserRouter>
                      <RedirectProvider>
                        <HeaderProvider>
                          <TitleProvider>
                            <BreadcrumbProvider>
                              <ConfirmProvider>
                                <HistorySocketProvider>
                                  <ChatProvider>
                                    <AppGlobalStyle />
                                    <Router />
                                  </ChatProvider>
                                </HistorySocketProvider>
                              </ConfirmProvider>
                            </BreadcrumbProvider>
                          </TitleProvider>
                        </HeaderProvider>
                      </RedirectProvider>
                    </BrowserRouter>
                  </SnackProvider>
                </LocalizationProvider>
              </PopupLoaderProvider>
            </LoadingProvider>
          </SnackbarProvider>
        </HelmetProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export { App };
