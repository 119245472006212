import swr, { SWRResponse } from 'swr';

import { toDate } from '@/util/date.ts';
import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { uuidValidator } from '../../common/input/uuid.input.ts';
import { GetTreeOutput } from './get-tree.output.ts';

const path = '/tree/:treeId';

export const getTree = async (treeId: string): Promise<GetTreeOutput> => {
  try {
    await uuidValidator.validate(treeId);
    const url = getUrl(path, { treeId });
    return await http.get<GetTreeOutput>(url).then((res) => ({
      ...res,
      createdAt: toDate(res.createdAt),
      releasedAt: toDate(res.releasedAt),
      receivedAt: toDate(res.receivedAt),
    }));
  } catch (err) {
    return handleError(path, err, { treeId });
  }
};

export const useGetTree = (treeId: string | null): SWRResponse<GetTreeOutput> => {
  const url = treeId ? getUrl(path, { treeId }) : null;
  return swr<GetTreeOutput>(url, () => getTree(treeId as string));
};
