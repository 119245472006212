import * as yup from 'yup';

import { HistoryAction, historyActionValues } from '@/enum/history/history-action.enum.ts';
import { HistoryEntity, historyEntityValues } from '@/enum/history/history-entity.enum.ts';

import { PageInput, pageValidator } from '../../common/input/page.input.ts';

type Filter = {
  actions?: HistoryAction[];
  entities?: HistoryEntity[];
  relationIds?: string[];
};

export type ListHistoriesBodyInput = PageInput & {
  filters?: Filter[];
  dateLeft?: Date;
  dateRight?: Date;
};

const filterValidator = yup.object({
  actions: yup.array().of(yup.string().oneOf(historyActionValues).required()).optional(),
  entities: yup.array().of(yup.string().oneOf(historyEntityValues).required()).optional(),
  relationIds: yup.array().of(yup.string().uuid().required()).optional(),
});

export const listHistoriesBodyValidator = yup
  .object({
    filters: yup.array().of(filterValidator).optional(),
    dateLeft: yup.date().optional(),
    dateRight: yup.date().optional(),
  })
  .concat(pageValidator);
