import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { UnimpersonateOutput } from './unimpersonate.output.ts';

const path = '/auth/unimpersonate';

export const unimpersonate = async (): Promise<string> => {
  try {
    const url = getUrl(path);
    return await http.post<UnimpersonateOutput>(url).then((res) => res.xsrf);
  } catch (err) {
    return handleError(path, err, {});
  }
};
