import Chip from '@mui/material/Chip';
import { ReactNode, useState } from 'react';

import { TreePopup } from '../TreePopup/TreePopup.tsx';

type Props = {
  tree?: { number: number };
  treeId: string;
  label?: string | number | ReactNode;
  sx?: object;
  onClose?: () => any;
};

export default function TreeItem({ tree, treeId, sx, onClose, label }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      {isOpen && (
        <TreePopup
          treeId={treeId}
          onClose={() => {
            setIsOpen(false);
            if (typeof onClose === 'function') onClose();
          }}
        />
      )}
      <Chip
        key={treeId}
        label={label || `A${tree?.number}`}
        sx={{ minWidth: '70px', ...sx }}
        onClick={() => setIsOpen(true)}
      />
    </>
  );
}
export { TreeItem };
