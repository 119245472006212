import swr, { SWRResponse } from 'swr';

import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { uuidValidator } from '../../common/input/uuid.input.ts';
import { GetMediaOutput } from './get-media.output.ts';

const path = '/media/:mediaId';

export const getMedia = async (mediaId: string): Promise<GetMediaOutput> => {
  try {
    await uuidValidator.validate(mediaId);
    const url = getUrl(path, { mediaId });
    return await http.get<GetMediaOutput>(url);
  } catch (err) {
    return handleError(path, err, { mediaId });
  }
};

export const useGetMedia = (mediaId: string | null): SWRResponse<GetMediaOutput> => {
  const url = mediaId ? getUrl(path, { mediaId }) : null;
  return swr<GetMediaOutput>(url, () => getMedia(mediaId as string));
};
