import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tooltip } from '@/component/Tooltip/Tooltip.tsx';
import { UserRole } from '@/enum/user/user-role.enum.ts';
import { useUserHasSomeRole } from '@/hook/user/useUserHasSomeRole.ts';
import { I18nNs } from '@/util/i18n.ts';

type Props = {
  id: string;
};

export function HistoryRelationCustomer({ id }: Props) {
  const { t } = useTranslation(I18nNs.History);
  const hasSomeRole = useUserHasSomeRole();

  const to = useMemo(() => {
    if (hasSomeRole(UserRole.Admin, UserRole.AdminReseller, UserRole.Commercial))
      return `/customer/show/${id}`;
    return null;
  }, [hasSomeRole, id]);

  if (!to) return null;

  return (
    <Tooltip title={t('btn.relation.show.customer')}>
      <IconButton component={Link} to={to} size="small">
        <PersonIcon />
      </IconButton>
    </Tooltip>
  );
}
