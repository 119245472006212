import isEqual from 'lodash/isEqual';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce, useUpdateEffect } from 'react-use';

import { deleteMedia } from '@/api/media/delete-media/delete-media.controller.ts';

import { useAddMedia } from '../hook/addMedia.ts';
import { MediaFieldContext, MediaFieldContextType } from './MediaFieldContext.ts';

type Props = {
  children: ReactNode;
  readOnly?: boolean;
  multi?: boolean;
  onChange: (value: string[]) => any;
  defaultValue: string | string[] | null;
  updateNameField?: boolean;
  extensions?: string[];
};

export function MediaFieldProvider({
  children,
  multi = false,
  readOnly = false,
  onChange,
  defaultValue,
  updateNameField = false,
  extensions = [],
}: Props): ReactElement {
  const [medias, setMedias] = useState<string[]>(() => {
    if (Array.isArray(defaultValue)) return defaultValue;
    if (typeof defaultValue === 'string') return [defaultValue];
    return [];
  });

  const oldDefaultValue = useRef<string[] | string | null>([]);
  useUpdateEffect(() => {
    if (isEqual(oldDefaultValue.current, defaultValue)) return;
    oldDefaultValue.current = defaultValue;
    setMedias(() => {
      if (Array.isArray(defaultValue)) return defaultValue;
      if (typeof defaultValue === 'string') return [defaultValue];
      return [];
    });
  }, [defaultValue]);

  const { addMedia, isFinish, pc } = useAddMedia(extensions);

  const onDrop = useCallback(
    (files: File[]) =>
      Promise.all(
        files.map(async (file: File) => {
          const id = await addMedia(file);
          if (id === null) return;
          if (multi) {
            setMedias((ms) => [...ms, id]);
          } else {
            setMedias([id]);
          }
        }),
      ),
    [addMedia, multi],
  );

  const onSwap = useCallback((a: number, b: number) => {
    setMedias((ms) =>
      ms.map((v, id, arr) => {
        if (id === a) return arr[b];
        if (id === b) return arr[a];
        return v;
      }),
    );
  }, []);

  const onDelete = useCallback(async (id: string) => {
    await deleteMedia(id);
    setMedias((ms) => ms.filter((m) => m !== id));
  }, []);

  const showDropzone = useMemo(() => {
    if (readOnly) return false;
    if (multi) return true;
    if (!isFinish) return false;
    if (medias.length === 0) return true;
    return false;
  }, [medias, readOnly, multi, isFinish]);

  const [run, cancel] = useDebounce(
    () => {
      onChange(medias);
    },
    500,
    [medias],
  );

  useEffect(() => {
    if (typeof onChange !== 'function') return () => true;
    run();
    return () => {
      cancel();
    };
  }, [onChange, run, cancel]);

  const value: MediaFieldContextType = useMemo(
    () => ({
      medias,
      onDrop,
      onDelete,
      readOnly,
      draggable: medias.length > 1,
      onSwap,
      multi,
      showDropzone,
      isFinish,
      pc,
      updateNameField: readOnly ? false : updateNameField,
    }),
    [
      medias,
      onDrop,
      onDelete,
      readOnly,
      onSwap,
      multi,
      showDropzone,
      isFinish,
      pc,
      updateNameField,
    ],
  );

  return <MediaFieldContext.Provider value={value}>{children}</MediaFieldContext.Provider>;
}
