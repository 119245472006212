import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

const path = '/media';

export const createMedia = async (
  file: File,
  onUploadProgress?: ({ loaded, total }: { loaded: number; total: number }) => void,
): Promise<string | null> => {
  try {
    const form = new FormData();
    form.append('file', file);
    const url = getUrl(path);
    return await http
      .postFormData<{ id: string }>(url, form, onUploadProgress)
      .then((res) => res?.id || null);
  } catch (err) {
    return handleError(path, err, { file, onUploadProgress });
  }
};
