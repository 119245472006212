import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { createMedia } from '@/api/media/create-media/create-media.controller.ts';
import { useSnack } from '@/context/SnackContext/useSnack.ts';
import { I18nNs } from '@/util/i18n.ts';

type Response = {
  pc: number;
  isFinish: boolean;
  addMedia: (file: File) => Promise<string | null>;
};

type Count = { [index: string]: [number, number] };

export function useAddMedia(extensions: string[]): Response {
  const [count, setCount] = useState<Count>({});
  const snack = useSnack();
  const { t } = useTranslation(I18nNs.Field);

  const onUploadProgress = useCallback((id: string, loaded: number, total: number | undefined) => {
    setCount((c) => ({
      ...c,
      [id]: [total || 0, loaded],
    }));
  }, []);

  const addMedia = useCallback(
    async (file: File): Promise<string | null> => {
      if (file.size >= 20971520) {
        snack.error(t('media.maxSize', { maxSize: '20Mo' }));
        return null;
      }

      if (extensions.length > 0) {
        const [extension] = file.name.split('.').reverse();
        if (!extensions.map((e) => e.toLowerCase()).includes(extension.toLowerCase())) {
          snack.error(t('media.extensions', { extensions: extensions.join(', ') }));
          return null;
        }
      }

      const tmpId = uuid();
      setCount((c) => ({ ...c, [tmpId]: [file.size, 0] }));
      const id = await createMedia(file, ({ loaded, total }) =>
        onUploadProgress(tmpId, loaded, total),
      );
      setCount((c) =>
        Object.keys(c).reduce((acc, key) => {
          if (key === tmpId) return acc;
          return { ...acc, [key]: c[key] };
        }, {}),
      );
      return id;
    },
    [onUploadProgress, snack, t, extensions],
  );

  const pc = useMemo(() => {
    const total = Object.keys(count).reduce((acc, key) => acc + count[key][0], 0);
    const loaded = Object.keys(count).reduce((acc, key) => acc + count[key][1], 0);

    if (total === 0) return 0;
    if (loaded === 0) return 0;
    const value = (loaded * 100) / total;
    return value < 100 ? value : 100;
  }, [count]);

  return {
    pc,
    addMedia,
    isFinish: pc === 0,
  };
}
