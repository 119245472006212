import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { useMediaField } from './context/useMediaField.ts';

export function Loader() {
  const { pc, isFinish } = useMediaField();

  if (isFinish) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={pc} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(pc)}%`}</Typography>
      </Box>
    </Box>
  );
}
