import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfirm } from '@/context/ConfirmContext/useConfirm.ts';
import { UserRole } from '@/enum/user/user-role.enum.ts';
import { useUserHasRole } from '@/hook/user/useUserHasRole.ts';
import { chatSocket } from '@/socket/chat/chat.socket.ts';
import { I18nNs } from '@/util/i18n.ts';

import { useChatStore } from '../../store/chat.store.ts';
import { StyledInfo } from './Info.style.ts';
import { InfoAddUser } from './InfoAddUser.tsx';

export function Info() {
  const confirm = useConfirm();
  const { t } = useTranslation(I18nNs.Chat);
  const hasRole = useUserHasRole();
  const members = useChatStore((s) => s.chat?.members);
  const room = useChatStore((s) => s.room);

  const onUnAssign = useCallback(
    async (userId: string, userName: string) => {
      if (!room) return;
      const ok = await confirm(t('confirm.unassign', { name: userName }));
      if (!ok) return;
      chatSocket.emit('unassignUserFromChat', { ...room.toJSON(), userId });
    },
    [confirm, room, t],
  );

  const onAssign = useCallback(
    async (userId: string, userName: string) => {
      if (!room) return;
      const ok = await confirm(t('confirm.assign', { name: userName }));
      if (!ok) return;
      chatSocket.emit('assignUserToChat', { ...room.toJSON(), userId });
    },
    [confirm, room, t],
  );

  const onRemoveUser = useCallback(
    async (id: string, userName: string) => {
      if (!room) return;
      const ok = await confirm(t('confirm.remove-user', { name: userName }));
      if (!ok) return;
      chatSocket.emit('removeUserFromChat', { ...room.toJSON(), userId: id });
    },
    [confirm, room, t],
  );

  const users = (members || []).filter((m) => !m.isAssigned);
  const assigneds = (members || []).filter((m) => m.isAssigned);
  const hasAssigned = assigneds.length > 0;

  return (
    <StyledInfo>
      <Typography variant="caption">{t('message.assigned')}</Typography>
      {hasAssigned ? (
        <List disablePadding component="div">
          {assigneds.map((assigned) => {
            return (
              <ListItemButton
                key={assigned.id}
                onClick={() => onUnAssign(assigned.id, assigned.name)}
                sx={{ pl: 0, pr: 0 }}
              >
                <ListItemIcon>
                  <Avatar sizes="small" src={assigned.avatar} alt={assigned.name}>
                    {assigned.initials}
                  </Avatar>
                </ListItemIcon>
                <ListItemText>{assigned.name}</ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      ) : (
        <Typography variant="body2">{t('message.helper.how-to-assign')}</Typography>
      )}

      <Box sx={{ mt: 3 }}>
        <Typography variant="caption">{t('message.members')}</Typography>
        <List disablePadding component="div">
          {users.length === 0 && (
            <ListItem component="div" sx={{ pl: 0, pr: 0 }}>
              <ListItemText>{t('message.no-member')}</ListItemText>
            </ListItem>
          )}
          {(users || []).map((u) => (
            <ListItem
              key={u.id}
              sx={{ p: 0 }}
              secondaryAction={
                hasRole(UserRole.Admin) ? (
                  <IconButton
                    onClick={() => onRemoveUser(u.id, u.name)}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null
              }
            >
              <ListItemButton sx={{ pl: 0, pr: 0 }} onClick={() => onAssign(u.id, u.name)}>
                <ListItemIcon>
                  <Avatar sizes="small" src={u.avatar} alt={u.name}>
                    {u?.initials}
                  </Avatar>
                </ListItemIcon>
                <ListItemText sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                  <span style={{ display: 'flex', fontSize: '14px' }}>{u.name}</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}

          <InfoAddUser />
        </List>
      </Box>
    </StyledInfo>
  );
}
