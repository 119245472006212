import AttachFileIcon from '@mui/icons-material/AttachFile';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotesIcon from '@mui/icons-material/Notes';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';

import { InputFile } from '../InputFile/InputFile.tsx';
import { InputRemember } from '../InputRemember/InputRemember.tsx';
import { InputText } from '../InputText/InputText.tsx';
import { StyledInput } from './Input.style.ts';

enum Option {
  Message = 'message',
  Remember = 'remember',
  File = 'file',
}

type Props = {
  className?: string;
};

export function Input({ className }: Props) {
  const [actual, setActual] = useState<Option>(Option.Message);

  return (
    <StyledInput className={className}>
      <Stack sx={{ width: '100%', mb: 0 }}>
        <ToggleButtonGroup
          exclusive
          size="small"
          value={actual}
          onChange={(_e, v) => {
            if (v !== null) setActual(v);
          }}
        >
          <ToggleButton value={Option.Message} sx={{ borderBottomLeftRadius: 0 }}>
            <NotesIcon />
          </ToggleButton>
          <ToggleButton value={Option.Remember}>
            <CalendarMonthIcon />
          </ToggleButton>
          <ToggleButton value={Option.File} sx={{ borderBottomRightRadius: 0 }}>
            <AttachFileIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {actual === Option.Message && <InputText />}
      {actual === Option.Remember && <InputRemember />}
      {actual === Option.File && <InputFile />}
    </StyledInput>
  );
}
