import * as Sentry from '@sentry/react';

import { config } from '../config.ts';

export const handleError = async (
  path: string,
  err: Error | unknown,
  metadata?: { [key: string]: any },
) => {
  console.error({
    path,
    err,
    metadata,
  });

  if (config.ENV !== 'development') {
    Sentry.captureException(err, {
      extra: {
        path,
        metadata: JSON.stringify(metadata),
        error: JSON.stringify(err),
      },
    });
  }

  throw err;
};
