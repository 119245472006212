import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { useGetPurchaseOrder } from '@/api/purchase/get-purchase-order/get-purchase-order.controller.ts';
import { useGetPurchaseProduct } from '@/api/purchase/get-purchase-product/get-purchase-product.controller.ts';
import { useGetTree } from '@/api/tree/get-tree/get-tree.controller.ts';
import { Line } from '@/component/Line/Line.tsx';
import { TreeStatus } from '@/enum/tree/tree-status.enum.ts';
import { getDateString, getDateWeek } from '@/util/date.ts';

type Props = {
  treeId: string;
};

export function TreeInfo({ treeId }: Props) {
  const { data: tree } = useGetTree(treeId);
  const { data: purchaseProduct } = useGetPurchaseProduct(tree?.purchaseProductId || null);
  const { data: purchaseOrder } = useGetPurchaseOrder(
    tree?.status === TreeStatus.Ordered ? tree?.purchaseOrderId : null,
  );

  if (!tree) return null;

  const treeModel = purchaseProduct?.treeModel;

  return (
    <Paper sx={{ padding: 2, mt: 2 }}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        {treeModel && (
          <Paper sx={{ padding: 3 }}>
            <Line name="Nom" value={treeModel.name} />
            <Line name="Model" value={treeModel.model} />
            <Line name="Taille" value={treeModel.size} />
            {treeModel.ao && <Line name="AO" value={treeModel.ao} />}
            {treeModel.pea && <Line name="PEA" value={treeModel.pea} />}
            {treeModel.per && <Line name="PER" value={treeModel.per} />}
            {treeModel.enfourchureEtroite && <Line name="Enfourchure étroite" value="OUI" />}
            {treeModel.fermoirs && <Line name="Fermoirs" value="OUI" />}
            {treeModel.light && <Line name="Light" value="OUI" />}
            {treeModel.tsqDroit && <Line name="Troussequin droit" value={treeModel.tsqDroit} />}
            {treeModel.tsqHaut && <Line name="Troussequin haut" value={treeModel.tsqHaut} />}
            {treeModel.largeur && <Line name="Largeur" value={treeModel.largeur} />}
          </Paper>
        )}
        <Paper sx={{ padding: 3 }}>
          <Line name="Reférence fournisseur" value={tree.refSupplier} />
          <Line name="Reférence Childéric" value={tree.refChilderic} />
          <Line name="Bon de commande" value={tree.purchaseOrderNumber || '-'} />
        </Paper>
        <Paper sx={{ padding: 3 }}>
          <Line name="Créé le" value={getDateString(tree.createdAt)} />

          {purchaseOrder?.before && (
            <Line
              name="Réception prévu"
              value={[
                getDateString(purchaseOrder?.before),
                `(S${getDateWeek(purchaseOrder?.before)})`,
              ].join(' ')}
            />
          )}

          <Line name="Reçu le" value={getDateString(tree.receivedAt)} />
          {tree.releasedAt && <Line name="Sortie le" value={getDateString(tree.releasedAt)} />}
        </Paper>
      </Stack>
    </Paper>
  );
}
