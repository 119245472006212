import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http, silenthttp } from '@/util/http/http.ts';

const path = '/auth/logout';

export const logout = async (silent: boolean = true): Promise<void> => {
  try {
    const url = getUrl(path);

    if (silent) {
      await silenthttp.get(url);
    } else {
      await http.get(url);
    }
  } catch (err) {
    handleError(path, err, { silent });
  }
};
