import * as yup from 'yup';

import { TreeStatus, treeStatusValues } from '@/enum/tree/tree-status.enum.ts';

import { TreeSizeInput, treeSizeValidator } from '../../common/input/tree-size.input.ts';

export type UpdateTreeBodyInput = {
  initial?: string;
  comment?: string;
  status?: TreeStatus;
  received?: TreeSizeInput;
  strapping?: TreeSizeInput;
  releasedAt?: Date;
  receivedAt?: Date;
};

export const updateTreeBodyValidator = yup.object({
  initial: yup.string().optional(),
  comment: yup.string().optional(),
  status: yup.string().oneOf(treeStatusValues).optional(),
  received: treeSizeValidator,
  strapping: treeSizeValidator,
  releasedAt: yup.date().optional(),
  receivedAt: yup.date().optional(),
});
