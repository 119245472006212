import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { LinkTargetBlank } from '../LinkTargetBlank/LinkTargetBlank.tsx';

type LineProps = {
  name: string;
  value: number | string;
  link?: string;
};

export function Line({ name, value, link }: LineProps) {
  if (value === '') return null;
  return (
    <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
      <Typography variant="body1">
        <strong>{name}:</strong>
      </Typography>
      {link && <LinkTargetBlank label={value} to={link} />}
      {!link && <Typography variant="body2">{value}</Typography>}
    </Stack>
  );
}
