export class HTTPError extends Error {
  constructor(
    message: string,
    public status: number,
  ) {
    super(message);

    if (status) {
      this.message = `Request failed with status code ${status}`;
    } else {
      this.message = `Request failed`;
    }
  }
}
