import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { nanoid } from 'nanoid';
import { ReactElement, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import { UseListAllOutput } from '@/api/common/output/use-list-all.output.ts';
import { UsePageOutput } from '@/api/common/output/use-page.output.ts';
import { ErrorMessage } from '@/field/ErrorMessage/ErrorMessage.tsx';
import { OptionType } from '@/type/option-search.type.ts';

import { useOptions } from '../../hook/useOptions.ts';
import { useValues } from '../../hook/useValues.ts';

interface Props<T extends FieldValues, List> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  useFind: (params?: object | null) => UsePageOutput<List> | UseListAllOutput<List>;
  findParams?: object;
  dbToOptionType: (data: List) => OptionType;
  loading?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export function SelectSearchMulti<T extends FieldValues, List>({
  control,
  label,
  name,
  useFind,
  findParams,
  dbToOptionType,
  loading = false,
  disabled = false,
  size,
}: Props<T, List>): ReactElement {
  const [id] = useState(nanoid());
  const [search, setSearch] = useState<string>('');

  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const values = useValues<List>({ values: field.value as string[], useFind, dbToOptionType });
  const options = useOptions<List>({ search, findParams, useFind, dbToOptionType, values });

  return (
    <FormControl error={!!error} sx={{ width: '100%' }} variant="outlined">
      {loading && <Skeleton variant="rectangular" height={56} />}
      {!loading && (
        <Autocomplete
          id={id}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          value={values}
          filterSelectedOptions
          disabled={disabled}
          selectOnFocus
          autoHighlight
          multiple
          size={size}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          noOptionsText="Rechercher..."
          onChange={(_event, newValues: OptionType[]) => {
            onChange({ target: { value: newValues.map((v) => v.value) || [] } });
          }}
          inputValue={search}
          onInputChange={(_event, newInputValue) => {
            setSearch(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label={label} fullWidth />}
        />
      )}
      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}
