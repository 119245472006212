import swr, { SWRResponse } from 'swr';

import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { uuidValidator } from '../../common/input/uuid.input.ts';
import { GetUserOutput } from './get-user.output.ts';

const path = '/user/:userId';

export const getUser = async (userId: string): Promise<GetUserOutput> => {
  try {
    await uuidValidator.validate(userId);
    const url = getUrl(path, { userId });
    return await http.get<GetUserOutput>(url);
  } catch (err) {
    return handleError(path, err, { userId });
  }
};

export const useGetUser = (userId: string | null): SWRResponse<GetUserOutput> => {
  const url = userId ? getUrl(path, { userId }) : null;
  return swr<GetUserOutput>(url, () => getUser(userId as string));
};
