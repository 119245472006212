import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';

import { errorEmitter } from '@/event/error.emitter.ts';
import { I18nNs } from '@/util/i18n.ts';

import { SnackAction, SnackContext, SnackVariant } from './SnackContext.ts';

const Style = createGlobalStyle`
  .notistack-SnackbarContainer {
    z-index: 9999999;
  }

`;

type Props = {
  children: React.ReactNode;
};

export function SnackProvider({ children }: Props): React.ReactElement {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation([I18nNs.Alert, I18nNs.Field]);

  const send = useCallback(
    (message: string, variant: SnackVariant, actions: SnackAction[] = []) => {
      const action = (key: SnackbarKey) => {
        // Actions is disabled, TODO: enable restore soft delete ?
        const Actions = actions.map((a) => (
          <Button key={a.label} onClick={() => a.onClick(() => closeSnackbar(key))}>
            {a.label}
          </Button>
        ));

        return (
          <>
            {actions.length === 0 && Actions}
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseIcon />
            </IconButton>
          </>
        );
      };

      return enqueueSnackbar(message, {
        variant,
        preventDuplicate: true,
        action,
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  const onSnackError = useCallback(
    (status: number) => {
      send(t(`error.${status}`), 'error', []);
    },
    [send, t],
  );

  useEffect(() => {
    errorEmitter.on('snack', onSnackError);
    return () => {
      errorEmitter.off('snack', onSnackError);
    };
  }, [onSnackError]);

  const value = useMemo(
    () => ({
      success: (message: string, actions?: SnackAction[]) => send(message, 'success', actions),
      error: (message: string, actions?: SnackAction[]) => send(message, 'error', actions),
      info: (message: string, actions?: SnackAction[]) => send(message, 'info', actions),
      warning: (message: string, actions?: SnackAction[]) => send(message, 'warning', actions),
      send(message: string, variant: SnackVariant, actions?: SnackAction[]) {
        return send(message, variant || 'default', actions);
      },
    }),
    [send],
  );

  return (
    <SnackContext.Provider value={value}>
      <Style />
      {children}
    </SnackContext.Provider>
  );
}
