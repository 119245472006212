import { Outlet, Route, Routes } from 'react-router-dom';

import { Suspense } from '@/component/Suspense/Suspense.tsx';
import { lazy } from '@/util/lazy/lazy.tsx';

const AdminRouter = lazy(() => import('./component/AdminRouter.tsx'));
const ChatScene = lazy(() => import('@/scene/ChatScene/ChatScene.tsx'));
const ComptaRouter = lazy(() => import('./component/ComptaRouter.tsx'));
const ConnectedLayout = lazy(() => import('@/layout/ConnectedLayout/ConnectedLayout.tsx'));
const CustomerScene = lazy(() => import('@/scene/CustomerScene/CustomerScene.tsx'));
const DashboardScene = lazy(() => import('@/scene/DashboardScene/DashboardScene.tsx'));
const ForgotScene = lazy(() => import('@/scene/ForgotScene/ForgotScene.tsx'));
const LoginScene = lazy(() => import('@/scene/LoginScene/LoginScene.tsx'));
const LogoutScene = lazy(() => import('@/scene/LogoutScene/LogoutScene.tsx'));
const MeScene = lazy(() => import('@/scene/MeScene/MeScene.tsx'));
const OfflineLayout = lazy(() => import('@/layout/OfflineLayout/OfflineLayout.tsx'));
const OrderRouter = lazy(() => import('./component/OrderRouter.tsx'));
const PayScene = lazy(() => import('@/scene/PayScene/PayScene.tsx'));
const PriceRouter = lazy(() => import('./component/PriceRouter.tsx'));
const ProductRouter = lazy(() => import('./component/ProductRouter.tsx'));
const PublicLayout = lazy(() => import('@/layout/PublicLayout/PublicLayout.tsx'));
const PublicScene = lazy(() => import('@/scene/PublicScene/PublicScene.tsx'));
const PurchaseRouter = lazy(() => import('./component/PurchaseRouter.tsx'));
const RedirectScene = lazy(() => import('@/scene/RedirectScene/RedirectScene.tsx'));
const ResellerRouter = lazy(() => import('./component/ResellerRouter.tsx'));
const SearchSaddleScene = lazy(() => import('@/scene/SearchSaddleScene/SearchSaddleScene.tsx'));
const StopImpersonateScene = lazy(
  () => import('@/scene/StopImpersonateScene/StopImpersonateScene.tsx'),
);
const TestScene = lazy(() => import('@/scene/TestScene/TestScene.tsx'));
const VoucherRouter = lazy(() => import('./component/VoucherRouter.tsx'));

export function Router() {
  return (
    <Routes>
      <Route
        element={
          <Suspense>
            <Outlet />
          </Suspense>
        }
      >
        <Route element={<PublicLayout />}>
          <Route path="/redirect/:table/:id" element={<RedirectScene />} />
          <Route path="/pub/*" element={<PublicScene />} />
          <Route path="/pay/:paymentId" element={<PayScene />} />
        </Route>
        <Route element={<OfflineLayout />}>
          <Route path="/" element={<LoginScene />} />
          <Route path="/forgot/*" element={<ForgotScene />} />
        </Route>
        <Route element={<ConnectedLayout />}>
          <Route path="/test/*" element={<TestScene />} />
          <Route path="/dashboard" element={<DashboardScene />} />
          <Route path="/logout" element={<LogoutScene />} />
          <Route path="/stop-impersonate" element={<StopImpersonateScene />} />

          <Route path="/admin/*" element={<AdminRouter />} />
          <Route path="/reseller/*" element={<ResellerRouter />} />
          <Route path="/product/*" element={<ProductRouter />} />
          <Route path="/compta/*" element={<ComptaRouter />} />
          <Route path="/price/*" element={<PriceRouter />} />
          <Route path="/purchase/*" element={<PurchaseRouter />} />

          <Route path="/customer/*" element={<CustomerScene />} />
          <Route path="/order/*" element={<OrderRouter />} />
          <Route path="/voucher/*" element={<VoucherRouter />} />

          <Route path="/me/*" element={<MeScene />} />
          <Route path="/chat/*" element={<ChatScene />} />
          <Route path="/search/*" element={<SearchSaddleScene />} />
        </Route>
      </Route>
    </Routes>
  );
}
