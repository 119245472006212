import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http, silenthttp } from '@/util/http/http.ts';

import { LoginBodyInput, loginBodyValidator } from './login.input.ts';
import { LoginOutput } from './login.output.ts';

const path = '/auth/login';

export const login = async (body: LoginBodyInput, silent: boolean = true): Promise<string> => {
  try {
    await loginBodyValidator.validate(body);
    const url = getUrl(path);

    if (silent) {
      return await silenthttp.post<LoginOutput>(url, body).then((res) => res?.xsrf);
    }

    return await http.post<LoginOutput>(url, body).then((res) => res?.xsrf);
  } catch (err) {
    return handleError(path, err, { login: body.login, password: 'xxx' });
  }
};
