import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { I18nNs } from '@/util/i18n.ts';

type Props = {
  title: string;
  open: boolean;
  children: React.ReactNode;
  onSave?: () => any;
  saveLabel?: string;
  onClose: () => any;
};
export function Popup({ open, children, title, onSave, onClose, saveLabel }: Props) {
  const { t } = useTranslation(I18nNs.Common);
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <AppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label={t('close')}>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          {onSave && (
            <Button autoFocus color="inherit" onClick={onSave}>
              {saveLabel || t('save')}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <div
        style={{
          padding: '100px 50px 50px',
          alignSelf: 'center',
          width: '100%',
          display: 'block',
          overflow: 'auto',
        }}
      >
        {children}
      </div>
    </Dialog>
  );
}
