import clsx from 'clsx';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { ScrollableDiv } from './ScrollToBottom.style.ts';
import { ScrollToBottomBtn } from './ScrollToBottomBtn.tsx';

type Props = {
  children: ReactNode;
  className?: string;
};
export const ScrollToBottom = ({ children, className }: Props) => {
  const [isScroll, setIsScroll] = useState<boolean>(false);

  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const messagesWrapperRef = useRef<HTMLDivElement>(null);
  const bottomAnchorRef = useRef<HTMLDivElement>(null);

  const isAtBottom = useCallback(() => {
    const scrollableContainer = scrollableContainerRef.current;
    if (!scrollableContainer) return true;
    return (
      scrollableContainer.scrollHeight - scrollableContainer.scrollTop ===
      scrollableContainer.clientHeight
    );
  }, []);

  const handleScroll = useCallback(() => {
    const scrollableContainer = scrollableContainerRef.current;
    if (!scrollableContainer) return;

    setTimeout(() => {
      const isBottom = isAtBottom();
      if (!isBottom) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }, 100);
  }, [isAtBottom]);

  const scrollToBottom = useCallback(() => {
    if (!bottomAnchorRef.current) return;
    bottomAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!messagesWrapperRef.current) return;
    if (!scrollableContainerRef.current) return;

    const scrollableContainer = scrollableContainerRef.current;
    const messagesWrapper = messagesWrapperRef.current;

    const resizeObserver = new ResizeObserver(() => {
      scrollableContainer.style.height = `${scrollableContainer.clientHeight}px`;

      if (!isScroll) {
        scrollToBottom();
      }
    });

    resizeObserver.observe(messagesWrapper);
    scrollableContainer.addEventListener('scroll', handleScroll);
    return () => {
      resizeObserver.unobserve(messagesWrapper);
      scrollableContainer.removeEventListener('scroll', handleScroll);
    };
  }, [isScroll, scrollToBottom, handleScroll, isAtBottom]);

  return (
    <div className={clsx(className, 'chat-message-container')}>
      <ScrollableDiv ref={scrollableContainerRef}>
        <div ref={messagesWrapperRef} className="chat-messages">
          {children}
          <div style={{ marginTop: 20 }} ref={bottomAnchorRef} />
        </div>
      </ScrollableDiv>
      <ScrollToBottomBtn isActive={isScroll} onClick={scrollToBottom} />
    </div>
  );
};
