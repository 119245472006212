import { ChatType } from '@/enum/chat/chat-type.enum.ts';

export class Room {
  private _type: ChatType;
  private _docId: string;

  constructor(type: ChatType, docId: string) {
    this._type = type;
    this._docId = docId;
  }

  get type(): ChatType {
    return this._type;
  }

  get docId(): string {
    return this._docId;
  }

  static create(type: ChatType, docId: string): Room {
    return new Room(type, docId);
  }

  public get value(): string {
    return [this._type, this._docId].join('_');
  }

  public toString(): string {
    return this.value;
  }

  public toJSON(): { type: ChatType; docId: string } {
    return {
      type: this.type,
      docId: this.docId,
    };
  }
}
