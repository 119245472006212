import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Fab from '@mui/material/Fab';
import clsx from 'clsx';

import { StyledChatScrollBottomBtn } from './ScrollToBottom.style.ts';

type Props = {
  onClick: () => void;
  isActive: boolean;
};

export function ScrollToBottomBtn({ isActive, onClick }: Props) {
  return (
    <StyledChatScrollBottomBtn className={clsx(isActive && 'sticky')} onClick={onClick}>
      <Fab color="primary" size="small">
        <KeyboardDoubleArrowDownIcon />
      </Fab>
    </StyledChatScrollBottomBtn>
  );
}
