import { FunctionComponent, useMemo } from 'react';

import { ListHistoriesOutput } from '@/api/history/list-histories/list-histories.output.ts';
import { HistoryEntity } from '@/enum/history/history-entity.enum.ts';

import { HistoryRelationComptaArticleFamily } from './component/HistoryRelationComptaArticleFamily.tsx';
import { HistoryRelationConfig } from './component/HistoryRelationConfig.tsx';
import { HistoryRelationCustomer } from './component/HistoryRelationCustomer.tsx';
import { HistoryRelationCustomerAddress } from './component/HistoryRelationCustomerAddress.tsx';
import { HistoryRelationDelivery } from './component/HistoryRelationDelivery.tsx';
import { HistoryRelationOrder } from './component/HistoryRelationOrder.tsx';
import { HistoryRelationOrderPayment } from './component/HistoryRelationOrderPayment.tsx';
import { HistoryRelationOrderProduct } from './component/HistoryRelationOrderProduct.tsx';
import { HistoryRelationOrderRecovery } from './component/HistoryRelationOrderRecovery.tsx';
import { HistoryRelationOrderSecondhand } from './component/HistoryRelationOrderSecondhand.tsx';
import { HistoryRelationPriceType } from './component/HistoryRelationPriceType.tsx';
import { HistoryRelationPurchaseCategory } from './component/HistoryRelationPurchaseCategory.tsx';
import { HistoryRelationPurchaseOrder } from './component/HistoryRelationPurchaseOrder.tsx';
import { HistoryRelationPurchaseOrderProduct } from './component/HistoryRelationPurchaseOrderProduct.tsx';
import { HistoryRelationPurchaseProduct } from './component/HistoryRelationPurchaseProduct.tsx';
import { HistoryRelationPurchaseProductPackaging } from './component/HistoryRelationPurchaseProductPackaging.tsx';
import { HistoryRelationPurchaseSupplier } from './component/HistoryRelationPurchaseSupplier.tsx';
import { HistoryRelationPurchaseUnit } from './component/HistoryRelationPurchaseUnit.tsx';
import { HistoryRelationRepairOrder } from './component/HistoryRelationRepairOrder.tsx';
import { HistoryRelationRepairOrderOption } from './component/HistoryRelationRepairOrderOption.tsx';
import { HistoryRelationRequestBy } from './component/HistoryRelationRequestBy.tsx';
import { HistoryRelationReseller } from './component/HistoryRelationReseller.tsx';
import { HistoryRelationResellerBy } from './component/HistoryRelationResellerBy.tsx';
import { HistoryRelationSecondhand } from './component/HistoryRelationSeconhand.tsx';
import { HistoryRelationSecondhandPlace } from './component/HistoryRelationSeconhandPlace.tsx';
import { HistoryRelationTree } from './component/HistoryRelationTree.tsx';
import { HistoryRelationUser } from './component/HistoryRelationUser.tsx';
import { HistoryRelationVat } from './component/HistoryRelationVat.tsx';
import { HistoryRelationVoucher } from './component/HistoryRelationVoucher.tsx';

type Props = {
  history: ListHistoriesOutput['docs'][0];
};

const relationOrder = [
  HistoryEntity.ResellerBy,
  HistoryEntity.RequestBy,

  HistoryEntity.Customer,
  HistoryEntity.Order,

  HistoryEntity.ComptaArticleFamily,
  HistoryEntity.Config,
  HistoryEntity.CustomerAddress,
  HistoryEntity.Delivery,
  HistoryEntity.OrderPayment,
  HistoryEntity.OrderProduct,
  HistoryEntity.OrderRecovery,
  HistoryEntity.OrderSecondhand,
  HistoryEntity.Payment,
  HistoryEntity.PriceType,
  HistoryEntity.PurchaseCategory,
  HistoryEntity.PurchaseOrder,
  HistoryEntity.PurchaseOrderProduct,
  HistoryEntity.PurchaseProduct,
  HistoryEntity.PurchaseProductPackaging,
  HistoryEntity.PurchaseSupplier,
  HistoryEntity.PurchaseUnit,
  HistoryEntity.RepairOrder,
  HistoryEntity.RepairOrderOption,
  HistoryEntity.Reseller,
  HistoryEntity.Secondhand,
  HistoryEntity.SecondhandPlace,
  HistoryEntity.Tree,
  HistoryEntity.User,
  HistoryEntity.Vat,
  HistoryEntity.Voucher,
];

const relationIndex = new Map<HistoryEntity, FunctionComponent<{ id: string }>>([
  [HistoryEntity.ComptaArticleFamily, HistoryRelationComptaArticleFamily],
  [HistoryEntity.Config, HistoryRelationConfig],
  [HistoryEntity.Customer, HistoryRelationCustomer],
  [HistoryEntity.CustomerAddress, HistoryRelationCustomerAddress],
  [HistoryEntity.Delivery, HistoryRelationDelivery],
  [HistoryEntity.Order, HistoryRelationOrder],
  [HistoryEntity.OrderPayment, HistoryRelationOrderPayment],
  [HistoryEntity.OrderProduct, HistoryRelationOrderProduct],
  [HistoryEntity.OrderRecovery, HistoryRelationOrderRecovery],
  [HistoryEntity.OrderSecondhand, HistoryRelationOrderSecondhand],
  [HistoryEntity.OrderPayment, HistoryRelationOrderPayment],
  [HistoryEntity.PriceType, HistoryRelationPriceType],
  [HistoryEntity.PurchaseCategory, HistoryRelationPurchaseCategory],
  [HistoryEntity.PurchaseOrder, HistoryRelationPurchaseOrder],
  [HistoryEntity.PurchaseOrderProduct, HistoryRelationPurchaseOrderProduct],
  [HistoryEntity.PurchaseProduct, HistoryRelationPurchaseProduct],
  [HistoryEntity.PurchaseProductPackaging, HistoryRelationPurchaseProductPackaging],
  [HistoryEntity.PurchaseSupplier, HistoryRelationPurchaseSupplier],
  [HistoryEntity.PurchaseUnit, HistoryRelationPurchaseUnit],
  [HistoryEntity.RepairOrder, HistoryRelationRepairOrder],
  [HistoryEntity.RepairOrderOption, HistoryRelationRepairOrderOption],
  [HistoryEntity.RequestBy, HistoryRelationRequestBy],
  [HistoryEntity.Reseller, HistoryRelationReseller],
  [HistoryEntity.ResellerBy, HistoryRelationResellerBy],
  [HistoryEntity.Secondhand, HistoryRelationSecondhand],
  [HistoryEntity.SecondhandPlace, HistoryRelationSecondhandPlace],
  [HistoryEntity.Tree, HistoryRelationTree],
  [HistoryEntity.User, HistoryRelationUser],
  [HistoryEntity.Vat, HistoryRelationVat],
  [HistoryEntity.Voucher, HistoryRelationVoucher],
]);

export function HistoryRelation({ history }: Props) {
  const relations = useMemo(
    () =>
      history.relationIds.sort(
        (a, b) =>
          relationOrder.findIndex((v) => v === a.entity) -
          relationOrder.findIndex((v) => v === b.entity),
      ),
    [history],
  );

  return (
    <>
      {relations.map(({ entity, relationId: id }) => {
        const Relation = relationIndex.get(entity);
        if (Relation) {
          return <Relation key={`${entity}_${id}`} id={id} />;
        }

        return null;
      })}
    </>
  );
}
