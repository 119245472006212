import { I18n } from '@/enum/common/i18n.enum.ts';
import { userStore } from '@/store/user/user.store.ts';

/**
 * Hook personnalisé pour récupérer la langue préférée de l'utilisateur.
 * @returns {I18n} - La langue préférée de l'utilisateur (fr ou en).
 */
export const useUserLang = (): I18n => {
  return userStore((s) => s.user?.lang || I18n.FR);
};
