import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { uuidValidator } from '../../common/input/uuid.input.ts';
import { UpdateTreeBodyInput, updateTreeBodyValidator } from './update-tree.input.ts';

const path = '/tree/:treeId';

export const updateTree = async (treeId: string, body: UpdateTreeBodyInput): Promise<void> => {
  try {
    await Promise.all([uuidValidator.validate(treeId), updateTreeBodyValidator.validate(body)]);
    const url = getUrl(path, { treeId });
    await http.patch(url, body);
  } catch (err) {
    handleError(path, err, { treeId, body });
  }
};
