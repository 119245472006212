import swr, { SWRResponse } from 'swr';

import { toDate } from '@/util/date.ts';
import { getUrl } from '@/util/get-url/get-url.ts';
import { handleError } from '@/util/handle-error/handleError.ts';
import { http } from '@/util/http/http.ts';

import { uuidValidator } from '../../common/input/uuid.input.ts';
import { GetPurchaseOrderOutput } from './get-purchase-order.output.ts';

const path = '/purchase-order/:purchaseOrderId';

export const getPurchaseOrder = async (
  purchaseOrderId: string,
): Promise<GetPurchaseOrderOutput> => {
  try {
    await uuidValidator.validate(purchaseOrderId);
    const url = getUrl(path, { purchaseOrderId });
    return await http.get<GetPurchaseOrderOutput>(url).then((res) => ({
      ...res,
      before: toDate(res.before),
      lastSendAt: toDate(res.lastSendAt),
      createdAt: toDate(res.createdAt),
    }));
  } catch (err) {
    return handleError(path, err, { purchaseOrderId });
  }
};

export const useGetPurchaseOrder = (
  purchaseOrderId: string | null,
): SWRResponse<GetPurchaseOrderOutput> => {
  const url = purchaseOrderId ? getUrl(path, { purchaseOrderId }) : null;
  return swr<GetPurchaseOrderOutput>(url, () => getPurchaseOrder(purchaseOrderId as string));
};
